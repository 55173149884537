import { LOAD_WCAG_SECTIONS, LOAD_WCAG_SECTION } from '../../config/actions';

export default (state = { wcagList: null, wcag: null }, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOAD_WCAG_SECTIONS:
      return Object.assign({}, state, { wcagList: payload.data });
    case LOAD_WCAG_SECTION:
      return Object.assign({}, state, { wcag: payload.data });

    default:
      return state;
  }
};