import {
  LOAD_MANUAL_REPORT,
  LOAD_MANUAL_REPORTS,
  LOAD_MANUAL_REPORTS_CHART,
  RESET_MANUAL_REPORT,
  SET_CONTACT_STATUS,
  RESET_MANUAL_REPORT_REPORT
} from "../../config/actions";

const report = (
  state = {
    reports: null,
    report: {
      title: "",
      objective: "",
      configuration: { build: "", os: "", browser: "", atTool: "" },
      issues: 0,
    },
    isLoading: true,
  },
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case LOAD_MANUAL_REPORT:
      return Object.assign({}, state, { ...payload });
    case LOAD_MANUAL_REPORTS:
      return Object.assign({}, state, { ...payload });
    case LOAD_MANUAL_REPORTS_CHART:
        return Object.assign({}, state, { ...payload });
    case RESET_MANUAL_REPORT:
      return Object.assign({}, state, {
        reports: null,
      });
    case RESET_MANUAL_REPORT_REPORT:
      return Object.assign({}, state, {
        report: {
          title: "",
          objective: "",
          configuration: { build: "", os: "", browser: "", atTool: "" },
          issues: 0,
        }
      });
    case SET_CONTACT_STATUS:
      return Object.assign({}, state, { contactStatus: payload.contactStatus });
    default:
      return state;
  }
};

export default report;